<template>
  <v-form ref="form" v-model="valid" @input="$emit('validity-changed', $event)">
    <div class="d-flex align-end gap-md mb-1">
      <AreaProfileMenu
        v-if="isIndividual && buildingProfiles.length"
        :profiles="buildingProfiles"
        @input="handleProfileSelect"
      />
    </div>

    <v-row dense>
      <v-col cols="8">
        <label
          for="building_area"
          class="caption"
          :class="{ 'required': isBuildingAreaRequired }"
        >
          Lettable Area
          <span v-if="isBuildingAreaRequired">*</span>
        </label>
        <div class="d-flex align-start gap-sm">
          <CurrencyText
            id="building_area"
            v-model="local.building_area"
            hide-details
            :rules="isBuildingAreaRequired ? [$rules.required] : []"
            :units="dropdowns.areaUnits"
            class="flex-grow-1"
          />
          <DropdownSelect
            v-model="local.building_area_type_id"
            ky="buildingAreaTypes"
            hide-details
            :clearable="false"
            class="shrinked"
          />
        </div>
      </v-col>
      <v-col cols="6">
        <label for="building_grade_id" class="caption">Building Grade (Optional)</label>
        <DropdownSelect
          id="building_grade_id"
          v-model="local.building_grade_id"
          hide-details
          ky="buildingGrades"
        />
      </v-col>
      <v-col cols="6">
        <label for="building_name" class="caption">Building Name (Optional)</label>
        <v-text-field
          v-model="local.building_name"
          id="building_name"
          outlined
          hide-details
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="6">
        <label for="number_of_childcare_places" class="caption">Number of Childcare Places</label>
        <CurrencyText
          id="number_of_childcare_places"
          v-model="local.number_of_childcare_places"
          :has-unit="false"
          hide-details
          :with-precision="false"
        />
      </v-col>
      <v-col v-if="eligibleForTypes(salesType, 'number_of_rooms', local.type_ids)" cols="6">
        <label for="number_of_rooms" class="caption">Number of Rooms/Keys</label>
        <CurrencyText
          id="number_of_rooms"
          v-model="local.number_of_rooms"
          :has-unit="false"
          hide-details
          :with-precision="false"
        />
      </v-col>
      <v-col v-if="eligibleForTypes(salesType, 'number_of_beds', local.type_ids)" cols="6">
        <label for="number_of_beds" class="caption">Number of Beds</label>
        <CurrencyText
          id="number_of_beds"
          v-model="local.number_of_beds"
          :has-unit="false"
          hide-details
          :with-precision="false"
        />
      </v-col>
      <v-col v-if="eligibleForTypes(salesType, 'number_of_gaming_machines', local.type_ids)" cols="6">
        <label for="number_of_gaming_machines" class="caption">Number of Gaming Machines</label>
        <CurrencyText
          id="number_of_gaming_machines"
          v-model="local.number_of_gaming_machines"
          :has-unit="false"
          hide-details
          :with-precision="false"
        />
      </v-col>
      <v-col cols="6">
        <label for="footprint_area" class="caption">Footprint Area (Optional)</label>
        <CurrencyText
          id="footprint_area"
          v-model="local.footprint_area"
          hide-details
          :units="dropdowns.areaUnits"
        />
      </v-col>
      <v-col cols="6">
        <label for="awning_height" class="caption">Awning Height (Optional)</label>
        <CurrencyText
          id="awning_height"
          v-model="local.awning_height"
          :has-unit="false"
          suffix="Meter(s)"
          hide-details
        />
      </v-col>
    </v-row>

    <ExpandPanel label="Area Breakdown" class="mt-6" content-class="">
      <v-row dense>
        <v-col cols="7">
          <label for="parking_type_ids" class="caption">Parking Types (Optional)</label>
          <DropdownSelect
            id="parking_type_ids"
            v-model="local.parking_type_ids"
            ky="parkingTypes"
            :multiple="true"
            hide-details
          />
        </v-col>
        <v-col cols="5">
          <label for="number_of_car_spaces" class="caption">Number of Car Spaces (Optional)</label>
          <CurrencyText
            id="number_of_car_spaces"
            v-model="local.number_of_car_spaces"
            :has-unit="false"
            suffix="space(s)"
            hide-details
            :with-precision="false"
          />
        </v-col>
        <template v-for="i in 7">
          <v-col cols="7" :key="`type-${i}`">
            <label :for="`area_${i}_type_id`" class="caption">Space {{ i }}</label>
            <DropdownSelect
              :id="`area_${i}_type_id`"
              v-model="local[`area_${i}_type_id`]"
              ky="buildingSpaceTypes"
              hide-details
            />
          </v-col>
          <v-col cols="5" :key="`area-${i}`">
            <label :for="`area_${i}`" class="caption">Space {{ i }} Area</label>
            <CurrencyText
              :id="`area_${i}`"
              v-model="local[`area_${i}`]"
              hide-details
              :units="dropdowns.areaUnits"
            />
          </v-col>
        </template>
      </v-row>
    </ExpandPanel>

    <ExpandPanel label="Add More Building Info" class="mt-5" content-class="">
      <v-row dense>
        <v-col cols="6">
          <label for="year_built" class="caption">Year Built (Optional)</label>
          <v-text-field
            v-model="local.year_built"
            id="year_built"
            outlined
            placeholder="YYYY"
            hide-details
            :rules="[$rules.yearOrNullRule]"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <label for="year_rennovated" class="caption">Year Rennovated (Optional)</label>
          <v-text-field
            v-model="local.year_rennovated"
            id="year_rennovated"
            outlined
            placeholder="YYYY"
            hide-details
            :rules="[$rules.yearOrNullRule, (v) => !v || v >= local.year_built || 'Year renovated should be more recent than year built']"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="6">
          <label for="number_of_floors" class="caption">Number of Floors (Optional)</label>
          <CurrencyText
            id="number_of_floors"
            v-model="local.number_of_floors"
            :has-unit="false"
            hide-details
            :with-precision="false"
          />
        </v-col>
        <v-col cols="6">
          <label for="number_of_basement_floors" class="caption">Number of Basement Floors (Optional)</label>
          <CurrencyText
            id="number_of_basement_floors"
            v-model="local.number_of_basement_floors"
            :has-unit="false"
            hide-details
            :with-precision="false"
          />
        </v-col>
        <v-col cols="6">
          <label for="building_status_id" class="caption">Building Status (Optional)</label>
          <DropdownSelect
            id="building_status_id"
            v-model="local.building_status_id"
            hide-details
            ky="buildingStatuses"
          />
        </v-col>
        <v-col cols="6">
          <label for="building_coverage_status_id" class="caption">Building Coverage Status (Optional)</label>
          <DropdownSelect
            id="building_coverage_status_id"
            v-model="local.building_coverage_status_id"
            hide-details
            ky="buildingCoverageStatuses"
          />
        </v-col>
        <v-col cols="6">
          <label for="building_level" class="caption">Building Level (Optional)</label>
          <v-text-field
            v-model="local.building_level"
            id="building_level"
            outlined
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <label for="building_level_coverage_status_id" class="caption">Building Level Coverage Status (Optional)</label>
          <DropdownSelect
            id="building_level_coverage_status_id"
            v-model="local.building_level_coverage_status_id"
            hide-details
            ky="buildingCoverageStatuses"
          />
        </v-col>
      </v-row>
    </ExpandPanel>

    <ExpandPanel label="Building Rating" class="mt-5">
      <v-row dense>
        <v-col cols="12">
          <div v-if="local.ratings.length === 0 && ratingFormEditInd === -1" class="mb-n2">
            <v-alert
              border="left"
              text
              dense
              type="warning"
            >
              <div>There are no rating added yet</div>
              <div>You can add at up to 5 ratings</div>
            </v-alert>
          </div>
          <div v-else-if="ratingFormEditInd === -1">
            <v-card v-for="(item, i) in local.ratings" :key="i" outlined class="d-flex align-center gap-md px-2 py-1 mb-2">
              <div>
                <v-list-item-title>
                  {{ dropdownItemName(item.rating_scope_id, 'buildingRatingScopes') }} |
                  {{ dropdownItemName(item.rating_type_id, 'buildingRatingTypes') }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  <v-rating
                    :value="item.rating"
                    color="yellow darken-3"
                    background-color="grey darken-1"
                    length="6"
                    half-increments
                    readonly
                    dense
                  />
                </v-list-item-subtitle>
              </div>
              <v-list-item-action class="flex-row align-center gap-sm ml-auto">
                <v-btn icon @click=handleEditRating(i)>
                  <v-icon small>$mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon @click="handleDeleteRating(i)">
                  <v-icon small>$mdi-trash-can</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-card>
          </div>
          <div v-if="ratingFormEditInd !== -1">
            <v-form ref="ratingForm">
              <v-row dense>
                <v-col cols="4">
                  <DropdownSelect
                    v-model="ratingForm.rating_scope_id"
                    ky="buildingRatingScopes"
                    :clearable="false"
                  />
                </v-col>
                <v-col cols="4">
                  <DropdownSelect
                    v-model="ratingForm.rating_type_id"
                    ky="buildingRatingTypes"
                    :clearable="false"
                  />
                </v-col>
                <v-col cols="4">
                  <DropdownSelect
                    v-model="ratingForm.rating"
                    ky="buildingStarRatings"
                    item-value="value"
                    :clearable="false"
                    :rules="[$rules.required]"
                  />
                </v-col>
              </v-row>
            </v-form>
            <div class="d-flex align-center mt-2">
              <v-btn v-if="local.ratings.length !== ratingFormEditInd" text color="error" @click="handleDeleteRating">
                <v-icon left>$mdi-trash-can</v-icon>
                Delete
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn text @click="handleCancelRatingForm">Cancel</v-btn>
              <v-btn color="primary" depressed class="ml-2" @click="handleSaveRatingForm">Save</v-btn>
            </div>
          </div>
          <div
            v-if="local.ratings.length < 5 && ratingFormEditInd === -1"
            class="text-center mt-1"
          >
            <v-btn
              depressed
              @click="handleAddMoreRating"
            ><v-icon left>$mdi-plus</v-icon>
              Add Rating
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </ExpandPanel>
  </v-form>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import {
  defaultRatingForm
} from '@/@propedia/default_forms'
import AreaProfileMenu from '@/components/AreaProfileMenu'

export default {
  props: [
    'value',
    'isIndividual',
    'address_detail_pid'
  ],
  components: {
    AreaProfileMenu
  },

  data() {
    return {
      valid: true,
      buildingProfiles: [],
      ratingFormEditInd: -1,
      ratingForm: structuredClone(defaultRatingForm)
    }
  },

  watch: {
    address_detail_pid: {
      immediate: true, // run watcher on mounted()
      handler(v) {
        if (v && this.isIndividual) {
          this.getBuildingProfiles()
        }
      }
    }
  },

  computed: {
    ...mapState('dropdown', ['dropdowns']),
    ...mapGetters(['salesType', 'isSalesComp']),
    ...mapGetters('dropdown', [
      'dropdownItemName',
      'eligibleForTypes'
    ]),
    local() {
      return this.value
    },
    isBuildingAreaRequired() {
      if (!this.isSalesComp) {
        return true
      } else {
        return this.local.existing_rights_of_transfer_id === 2
      }
    }
  },

  methods: {
    ...mapActions('property', [
      'getCompTransactions'
    ]),
    update(key, value) {
      this.$emit('input', {
        ...this.local,
        [key]: value
      })
    },
    handleAddMoreRating() {
      this.ratingForm = structuredClone(defaultRatingForm)
      this.ratingFormEditInd = this.local.ratings.length
    },
    handleCancelRatingForm() {
      this.ratingFormEditInd = -1
    },
    handleEditRating(i) {
      this.ratingForm = structuredClone(this.local.ratings[i])
      this.ratingFormEditInd = i
    },
    handleDeleteRating(i = null) {
      const ratings = this.local.ratings
      const index = i ?? this.ratingFormEditInd

      ratings.splice(index, 1)
      this.update('ratings', ratings)

      this.ratingFormEditInd = -1
    },
    handleSaveRatingForm() {
      if (this.$refs.ratingForm.validate()) {
        if (this.ratingFormEditInd === this.local.ratings.length) { // Add new
          this.local.ratings = [...this.local.ratings, structuredClone(this.ratingForm)]
        } else { // Update
          this.local.ratings[this.ratingFormEditInd] = structuredClone(this.ratingForm)
        }

        this.ratingFormEditInd = -1
      }
    },
    getBuildingProfiles() {
      this.getCompTransactions({
        address_detail_pids: [this.address_detail_pid],
        with_building_details: true
      }).then((res) => {
        const { salesItems, leaseItems } = res.data

        this.buildingProfiles = [...salesItems, ...leaseItems].sort((a, b) => {
          const dtA = a.sale_date ? a.sale_date : (a.lease_date ? a.lease_date : 0)
          const dtB = b.sale_date ? b.sale_date : (b.lease_date ? b.lease_date : 0)

          return dtA > dtB ? -1 : (dtA < dtB ? 1 : 0)
        })
      })
    },
    handleProfileSelect(item) {
      const building = item.building

      this.$emit('input', {
        ...this.local,
        is_crownland: building.is_crownland,
        building_area: {
          value: building.building_area,
          unit: building.building_area_unit
        },
        land_area: {
          value: building.land_area,
          unit: building.land_area_unit
        },
        building_grade_id: building.building_grade_id,
        number_of_childcare_places: building.number_of_childcare_places,
        number_of_rooms: building.number_of_rooms,
        building_name: building.building_name,
        footprint_area: {
          value: building.footprint_area,
          unit: building.footprint_area_unit
        },
        awning_height: building.awning_height,
        parking_type_ids: building.parking_type_ids,
        number_of_car_spaces: building.number_of_car_spaces,
        year_built: building.year_built,
        year_rennovated: building.year_rennovated,
        number_of_floors: building.number_of_floors,
        number_of_basement_floors: building.number_of_basement_floors,
        building_status_id: building.building_status_id,
        building_coverage_status_id: building.building_coverage_status_id,
        building_level: building.building_level,
        building_level_coverage_status_id: building.building_level_coverage_status_id,
        ratings: building.ratings,
        
        area_1_type_id: building.area_1_type_id,
        area_1: {
          value: building.area_1,
          unit: building.area_1_unit
        },
        area_2_type_id: building.area_2_type_id,
        area_2: {
          value: building.area_2,
          unit: building.area_2_unit
        },
        area_3_type_id: building.area_3_type_id,
        area_3: {
          value: building.area_3,
          unit: building.area_3_unit
        },
        area_4_type_id: building.area_4_type_id,
        area_4: {
          value: building.area_4,
          unit: building.area_4_unit
        },
        area_5_type_id: building.area_5_type_id,
        area_5: {
          value: building.area_5,
          unit: building.area_5_unit
        },
        area_6_type_id: building.area_6_type_id,
        area_6: {
          value: building.area_6,
          unit: building.area_6_unit
        },
        area_7_type_id: building.area_7_type_id,
        area_7: {
          value: building.area_7,
          unit: building.area_7_unit
        }
      })
    },
  }
}
</script>