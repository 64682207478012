<template>
  <div>
    <v-form ref="form" v-model="valid" @input="$emit('validity-changed', $event)">
      <v-row dense>
        <v-col cols="6">
          <label for="sale_price" class="caption required">
            {{ local.is_multi_sale ? 'Combined Total Sale Price' : 'Sale Price' }} <span>*</span>
          </label>
          <CurrencyText
            id="sale_price"
            v-model="local.sale_price"
            :has-unit="false"
            prefix="$"
            :rules="[$rules.required]"
            hide-details
          />
        </v-col>
        <v-col cols="6">
          <label for="sale_dt" class="caption required">Sale Date<span>*</span></label>
          <DatePickerText
            id="sale_dt"
            v-model="local.sale_dt"
            dense
            outlined
            hide-details
            :rules="[$rules.required, $rules.dateOrNullRule, $rules.dateNotFuture]"
          />
        </v-col>
      </v-row>

      <!-- GST Included -->
      <v-row dense class="mb-2">
        <v-col cols="6" class="d-flex align-center">
          <v-checkbox
            v-model="local.gst_included"
            label="Price includes GST"
            hide-details
            class="ml-n1"
          />
          <v-tooltip right max-width="300">
            <template v-slot:activator="{ on, attrs }">
              <v-icon right size="18" v-bind="attrs" v-on="on">$mdi-information</v-icon>
            </template>
            <span>Please tick this box if the Sale Price entered is GST inclusive.</span>
          </v-tooltip>
        </v-col>
      </v-row>
      
      <v-row v-if="typeIds.includes(100)" dense class="mb-3">
        <v-col cols="6">
          <label for="proportional_land_value" class="caption">
            Apportioned Land Value
          </label>
          <CurrencyText
            id="proportional_land_value"
            v-model="local.proportional_land_value"
            :has-unit="false"
            prefix="$"
            hide-details
          />
        </v-col>
        <v-col cols="6">
          <label for="proportional_building_value" class="caption">
            Apportioned Building Value
          </label>
          <CurrencyText
            id="proportional_building_value"
            v-model="local.proportional_building_value"
            :has-unit="false"
            prefix="$"
            hide-details
          />
        </v-col>
      </v-row>

      <!-- Payment Details -->
      <v-row dense>
        <v-col cols="12">
          <label for="settlement_term_type_id" class="caption">Payment details / Terms of sale</label>
          <DropdownSelect
            id="settlement_term_type_id"
            v-model="local.settlement_term_type_id"
            ky="settlementTermTypes"
            hide-details
            @input="handlePaymentDetailIdChange"
          />
        </v-col>
        <v-col v-if="local.settlement_term_type_id === 3" cols="12">
          <v-row dense style="margin-bottom: -26px;">
            <v-col cols="3"></v-col>
            <v-col cols="3"></v-col>
            <v-col cols="2" class="caption" style="padding-left: 12px;">$ Value (excl. GST)</v-col>
            <v-col cols="2" class="caption" style="padding-left: 4px;">$ Value (incl. GST)</v-col>
            <v-col cols="2" class="caption">Due Date</v-col>
          </v-row>
          <div v-for="i in 6" :key="i" class="mt-1">
            <label :for="`term_${i}`" class="caption">{{ i === 1 ? 'Deposit' : `Payment ${i}` }}</label>
            <v-row dense>
              <v-col cols="3">
                <CurrencyText
                  :id="`term_${i}`"
                  v-model="local.settlement_term_details[i - 1][0]"
                  suffix="%"
                  :has-unit="false"
                  hide-details
                />
              </v-col>
              <v-col cols="3">
                <CurrencyText
                  :id="`term_${i}`"
                  v-model="local.settlement_term_details[i - 1][1]"
                  suffix="Months"
                  :has-unit="false"
                  hide-details
                />
              </v-col>
              <v-col cols="2">
                <NormalField
                  type="price"
                  :value="termPrice(local, i, true)"
                  style="line-height: 40px; padding-left: 8px;"
                />
              </v-col>
              <v-col cols="2">
                <NormalField
                  type="price"
                  :value="termPrice(local, i, false)"
                  style="line-height: 40px;"
                />
              </v-col>
              <v-col cols="2">
                <NormalField
                  v-if="local.sale_dt && local.settlement_term_details[i - 1][1]"
                  :value="termDate(local, i)"
                  style="line-height: 40px;"
                />
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col v-if="local.settlement_term_type_id === 2 || local.settlement_term_type_id === 3" cols="12">
          <label for="settlement_term_commentary" class="caption">Terms of sale commentary</label>
          <v-textarea
            id="settlement_term_commentary"
            v-model="local.settlement_term_commentary"
            outlined
            :clearable="false"
            rows="3"
            hide-details
            @input="update('settlement_term_commentary', $event)"
          ></v-textarea>
        </v-col>
      </v-row>

      <ExpandPanel label="Current Sale Status, Ownership Transfer" class="mt-6">
        <v-row dense>
          <v-col cols="12" class="d-flex flex-column">
            <label class="caption">Current Sale Status</label>
            <BtnSelection
              v-model="local.sale_status_id"
              ky="saleStatuses"
              mandatory
              grow
            />
          </v-col>
          <v-col cols="6">
            <label class="caption" for="ownership_transfer">Ownership Transfer</label>
            <CurrencyText
              id="ownership_transfer"
              v-model="local.ownership_transfer"
              suffix="%"
              :has-unit="false"
              hide-details
            />
          </v-col>
        </v-row>
      </ExpandPanel>

      <ExpandPanel label="Purchaser, Vendor" class="mt-5">
        <v-row dense>
          <v-col cols="12">
            <div>
              <label for="vendors" class="caption">Vendors</label>
              <ContactAutoComplete
                id="vendors"
                v-model="local.vendors"
                :multiple="true"
                main-type="Vendor"
                :query-types="['Vendor', 'Purchaser']"
                @input="update('vendor_ids', local.vendors.map((v) => v.id))"
              />
            </div>
          </v-col>
          <v-col cols="12">
            <div>
              <label for="purchasers" class="caption">Purchasers</label>
              <ContactAutoComplete
                id="purchasers"
                v-model="local.purchasers"
                :multiple="true"
                main-type="Purchaser"
                :query-types="['Vendor', 'Purchaser']"
                @input="update('purchaser_ids', local.purchasers.map((v) => v.id))"
              />
            </div>
          </v-col>
        </v-row>
      </ExpandPanel>

      <ExpandPanel v-if="typeIds.includes(70)" label="Hospitality" class="mt-5">
        <v-row dense>
          <v-col cols="6">
            <label for="turnover" class="caption">Turnover</label>
            <div>
              <CurrencyText
                id="turnover"
                v-model="local.turnover"
                prefix="$"
                hide-details
                :has-unit="false"
                :disabled="local.is_turnover_confidential"
              />
              <v-checkbox
                v-model="local.is_turnover_confidential"
                label="Confidential"
                hide-details
                class="ml-n1 pt-0"
                @change="update('turnover', null)"
              />
            </div>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="6">
            <label for="ebitda" class="caption">Ebitda</label>
            <div>
              <CurrencyText
                id="ebitda"
                v-model="local.ebitda"
                prefix="$"
                hide-details
                :has-unit="false"
                :disabled="local.is_ebitda_confidential"
              />
              <v-checkbox
                v-model="local.is_ebitda_confidential"
                label="Confidential"
                hide-details
                class="ml-n1 pt-0"
                @change="update('ebitda', null)"
              />
            </div>
          </v-col>
          <v-col cols="6">
            <label for="ebitdar" class="caption">Ebitdar</label>
            <div>
              <CurrencyText
                id="ebitdar"
                v-model="local.ebitdar"
                prefix="$"
                hide-details
                :has-unit="false"
                :disabled="local.is_ebitdar_confidential"
              />
              <v-checkbox
                v-model="local.is_ebitdar_confidential"
                label="Confidential"
                hide-details
                class="ml-n1 pt-0"
                @change="update('ebitdar', null)"
              />
            </div>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="6">
            <label for="multiplier" class="caption">Multiplier</label>
            <CurrencyText
              id="multiplier"
              v-model="local.multiplier"
              hide-details
              :has-unit="false"
            />
          </v-col>
        </v-row>
      </ExpandPanel>

      <v-row dense class="mt-2">
        <v-col cols="12">
          <label for="analysis_comments" class="caption">Sale Analysis Comments</label>
          <v-textarea
            id="analysis_comments"
            v-model="local.analysis_comments"
            outlined
            hide-details
            :clearable="false"
            rows="3"
          />
        </v-col>
        <v-col cols="12">
          <label for="description" class="caption required">Sales Description / Commentary <span>*</span></label>
          <v-textarea
            id="description"
            v-model="local.description"
            outlined
            hide-details
            :clearable="false"
            rows="3"
            :rules="[$rules.required]"
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col>
          <div>
            <label class="caption required">Tenure Type<span>*</span></label>
            <v-tooltip right max-width="300">
              <template v-slot:activator="{ on, attrs }">
                <v-icon right size="18" v-bind="attrs" v-on="on">$mdi-information</v-icon>
              </template>
              <span>The property has a tenant and lease in place. Lease details must be provided.</span>
            </v-tooltip>
          </div>
          <BtnSelection
            v-model="local.tenure_type_id"
            ky="tenureTypes"
            mandatory
          />
        </v-col>
      </v-row>

      <div v-if="local.tenure_type_id === 2">
        <v-row dense class="mb-2 mt-2">
          <v-col cols="7">
            <div>
              <label for="passing_rent" class="caption required">
                {{ local.is_multi_sale ? 'Combined Passing Rent' : 'Passing Rent' }}
                <span>*</span>
              </label>
              <div class="d-flex align-start gap-sm">
                <div class="flex-grow-1">
                  <CurrencyText
                    id="passing_rent"
                    v-model="local.passing_rent"
                    prefix="$"
                    hide-details
                    :has-unit="false"
                    :rules="[$rules.required]"
                  />
                  <v-checkbox
                    v-model="local.is_passing_rent_approx"
                    label="Approx"
                    hide-details
                    class="ml-n1 pt-0"
                  />
                </div>
                <DropdownSelect
                  v-model="local.passing_rent_type_id"
                  ky="leaseAmountTypes"
                  hide-details
                  class="shrinked"
                  :clearable="false"
                />
              </div>
            </div>
          </v-col>
          <v-col v-if="eligibleForTypes(salesType, 'effective_dt', typeIds)" cols="5">
            <label for="effective_dt" class="caption">Effective Date</label>
            <DatePickerText
              id="effective_dt"
              v-model="local.effective_dt"
              dense
              outlined
              hide-details
              :rules="[$rules.dateOrNullRule]"
            />
          </v-col>
        </v-row>
      </div>
    </v-form>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  components: {
  },

  props: [
    'value',
    'salesType',
    'isMultiSale',
    'typeIds',
    'address',
    'addresses',
    'leaseProfiles',
    'leaseSelected',
    'isLandAreaRequired'
  ],

  data() {
    return {
      valid: true
    }
  },

  computed: {
    ...mapState('dropdown', ['dropdowns']),
    ...mapGetters('dropdown', [
      'eligibleForTypes'
    ]),
    ...mapGetters('property', [
      'termPrice',
      'termDate'
    ]),
    local() {
      return this.value
    }
  },

  methods: {
    handlePaymentDetailIdChange(v) {
      if (v !== 3) {
        this.update('settlement_term_details', [
          [null, null],
          [null, null],
          [null, null],
          [null, null],
          [null, null],
          [null, null]
        ])
      }
    },
    isLeaseProfileFilled(address_detail_pid) {
      if (!this.leaseProfiles) {
        return false
      }

      return !!(this.leaseProfiles[address_detail_pid].commencing_rent && this.leaseProfiles[address_detail_pid].lease_dt)
    },
    update(key, value) {
      this.$emit('input', {
        ...this.local,
        [key]: value
      })
    }
  }
}
</script>