<template>
  <div class="relative" style="min-height: 12px;">
    <a @click="expanded = !expanded" class="userselect-none" style="position: absolute; top: -12px; left: 16px; background: white; z-index: 1;">
      <span v-if="expanded">
        {{ label }}
        <v-icon color="primary" small>$mdi-chevron-up</v-icon>
      </span>
      <span v-else>
        {{ label }}
        <v-icon color="primary" small>$mdi-chevron-down</v-icon>
      </span>
    </a>
    <v-sheet
      outlined
      rounded
      class="pt-3 pb-2 relative"
      :class="{'cursor-pointer' : !expanded}"
      @click="expanded = true"
    >
      <div v-if="!expanded" style="position: absolute; top: 0; left: 50%; transform: translateX(-50%); font-size: 18px; line-height: 8px;">...</div>
      <v-expand-transition>
        <div v-show="expanded" :class="contentClass">
          <div class="py-2 px-3">
            <slot />
          </div>
        </div>
      </v-expand-transition>
    </v-sheet>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    contentClass: {
      type: String
    }
  },
  data() {
    return {
      expanded: false
    }
  }
}
</script>