import { SALES_COMP, LEASE_COMP, ALL } from '@/@propedia/constants/sales_types'
import { ADDRESS } from '@/@propedia/constants/attribute_groups'

import api from '@/api'

export default {
  namespaced: true,

  state: {
    allColumns: [],
    initialColumns: [],
    userColumns: {
      salesComp: [],
      leaseComp: []
    },
    userShortlistColumns: {
      salesComp: [],
      leaseComp: []
    },
    mappers: {
      salesComp: SALES_COMP,
      leaseComp: LEASE_COMP
    }
  },

  mutations: {
    SET_ALL_COLUMNS(state, columns) {
      state.allColumns = columns
    },
    SET_INITIAL_COLUMNS(state, columns) {
      state.initialColumns = columns.filter((col) => col.initial === true)
    },
    SET_USER_COLUMNS(state, columns) { state.userColumns = columns },
    SET_SHOURTLIST_COLUMNS(state, columns) { state.userShortlistColumns = columns }
  },

  actions: {
    updateSettingValue(_, { key, value, description }) {
      return api.put('/settings', {
        key,
        value,
        description
      })
    },
    getSettingValueByKey(_, key) {
      return api.get(`/settings/${key}`)
    },
    getSettingsByKeys(_, keys) {
      return api.get('/settings/by_keys', {
        params: {
          keys
        }
      })
    },
    async getColumns({ commit }) {
      const response = await api.get('columns/all')

      commit('SET_ALL_COLUMNS', response.data)
      commit('SET_INITIAL_COLUMNS', response.data.filter((col) => col.initial === true))
    },

    async getUserColumns({ state, commit }) {
      const response = await api.get('columns')

      const salesCompColumn = response.data.find((col) => col.salesType === SALES_COMP)
      const leaseCompColumn = response.data.find((col) => col.salesType === LEASE_COMP)

      commit('SET_USER_COLUMNS', {
        salesComp: salesCompColumn?.columnIds ?
          state.allColumns.filter((col) => salesCompColumn.columnIds.includes(col.id)) :
          state.initialColumns.filter((col) => (col.type === state.mappers.salesComp || col.type === ALL)),
        leaseComp: leaseCompColumn?.columnIds ?
          state.allColumns.filter((col) => leaseCompColumn.columnIds.includes(col.id)) :
          state.initialColumns.filter((col) => (col.type === state.mappers.leaseComp || col.type === ALL))
      })

      commit('SET_SHOURTLIST_COLUMNS', {
        salesComp: salesCompColumn?.shortlistColumnIds ? state.allColumns.filter((col) => salesCompColumn.shortlistColumnIds.includes(col.id)) : state.initialColumns.filter((col) => (col.type === state.mappers.salesComp || col.type === ALL)),
        leaseComp: leaseCompColumn?.shortlistColumnIds ? state.allColumns.filter((col) => leaseCompColumn.shortlistColumnIds.includes(col.id)) : state.initialColumns.filter((col) => (col.type === state.mappers.leaseComp || col.type === ALL))
      })
    },

    updateUserColumns: (_, payload) => {
      return api.post('/columns/update', payload)
    }
  },
  getters: {
    allColumnsBySalesType: (state) => (type = 'salesComp') => state.allColumns.filter((col) => col.type === state.mappers[type] || col.type === ALL),

    shortlistColumns: (state) => (type = 'salesComp') => {
      return state.userShortlistColumns[type].filter((col) => col.group !== ADDRESS)
    }
  }
}