import api from '@/api'

export default {
  namespaced: true,

  state: {
    dropdowns: {}
  },
  mutations: {
    SET_DROPDOWNS(state, items) {
      // const buildingTypes = items.buildingTypes.map((v) => {
      //   return v.parent_id ? v : {
      //     header: v.name
      //   }
      // })

      state.dropdowns = items
    }
  },
  actions: {
    getDropdowns({ commit }) {
      api.get('/app/dropdowns').then((res) => {
        commit('SET_DROPDOWNS', res.data.items)
      })
    }
  },
  getters: {
    dropdownItem: (state) => (k, id) => {
      return state.dropdowns[k] ? state.dropdowns[k].find((v) => v.id == id) : null
    },
    isDevelopmentSiteSelected: (state) => (ids) => {
      if (state.dropdowns.buildingTypes) {
        const selectedTypes = state.dropdowns.buildingTypes.filter((v) => ids.includes(v.id))
        
        return selectedTypes.map((v) => v.parent_id).includes(220) || selectedTypes.map((v) => v.id).includes(220)
      } else {
        return false
      }
    },
    eligibleForTypes: (state, getters, rootState, rootGetters) => (salesType, column_name, type_ids) => {
      const columns = rootGetters['settings/allColumnsBySalesType'](salesType)
      const column = columns.find((v) => v.column_name === column_name)

      if (!column) {
        return false
      }

      if (column.elgible_type_ids) {
        return type_ids.some((type_id) => column.elgible_type_ids.includes(type_id))
      } else {
        return true
      }
    },
    parentTypeIds: (state) => (childIds) => {
      if (state.dropdowns.buildingTypes) {
        const parentItems = state.dropdowns.buildingTypes.filter((v) => childIds.includes(+v.id))
  
        return parentItems.map((v) => v.parent_id)
      }

      return []
    },

    /**
     * From parent object, return children ids
     */
    childrenIds: (state) => (parent) => {
      const children = state.dropdowns.buildingTypes.filter((v) => v.parent_id === parent.id)

      return children.map((v) => v.id)
    },

    /**
     * Return type objects from ids
     */
    typesFromIds: (state) => (ids) => {
      return state.dropdowns.buildingTypes.filter((v) => ids.includes(v.id))      
    },

    /**
     * Returns parent type regardless provided id is for parent or child
     */
    parentTypeName: (state) => (childId) => {
      const item = state.dropdowns.buildingTypes.find((v) => v.id === childId)

      if (item) {
        if (item.parent_id) {
          const parentItem = state.dropdowns.buildingTypes.find((v) => v.id === item.parent_id)

          return parentItem.name
        } else {
          return item.name
        }
      } else {
        return null
      }
    },
    
    dropdownItemName: (state) => (id, key) => {
      let item = null

      if (state.dropdowns[key]) {
        item = state.dropdowns[key].find((v) => v == id)

        if (!item) {
          item = state.dropdowns[key].find((v) => v.id == id)
        }
      } else {
        console.log(key)
      }

      return item?.name
    }
  }
}